<template>
  <CompaniesForm
    @save="updateAgency"
    :company="company"
    :type="'edit'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CompaniesForm: () => import('./CompaniesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    company: {},
    askToLeave: true,
  }),
  methods: {
    getCurrentCompany() {
      this.$api
        .get(`companies/${this.$route.params.id}`)
        .then((res) => {
          this.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateAgency() {
      this.askToLeave = false

      let hasFile = this.company.logo instanceof File

      if (hasFile) {
        let formData = new FormData()
        for (let key in this.company) {
          if (key === 'logo' && this.company[key] instanceof File) {
            formData.append(key, this.company[key], this.company[key].name)
          } else {
            formData.append(key, this.company[key] ?? '')
          }
        }

        this.$api
          .post(`companies/update/${this.$route.params.id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Empresa atualizada com sucesso',
              confirm: () => {
                this.$router.push({ name: 'agencies' })
              },
            })
          })
          .catch(() => {})
      } else {
        this.$api
          .post(`companies/update/${this.$route.params.id}`, this.company)
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Empresa atualizada com sucesso',
              confirm: () => {
                this.$router.push({ name: 'agencies' })
              },
            })
          })
          .catch(() => {})
      }
    },
  },
  created() {
    this.getCurrentCompany()
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
