<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{ item.number }}</span>
    </v-subheader>

    <v-list-item
      class="mt-2"
      link
      @click="sendModal"
    >
      <v-list-item-icon>
        <v-icon>fas fa-paper-plane</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Enviar</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      class="mt-2"
      link
      @click="duplicateBudget"
    >
      <v-list-item-icon>
        <v-icon>fas fa-copy</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Duplicar</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.budget_status != 2 && item.budget_status != 3"
      class="mt-2"
      link
      @click="approveBudget"
    >
      <v-list-item-icon>
        <v-icon>fas fa-check</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Aprovar</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="item.budget_status != 2 && item.budget_status != 3"
      class="mt-2"
      link
      @click="disapproveBudget"
    >
      <v-list-item-icon>
        <v-icon>fas fa-times-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Reprovar</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      class="mt-2"
      link
      @click="showPDF"
    >
      <v-list-item-icon>
        <v-icon>fas fa-print</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Visualizar PDF</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="showDeleteBudgets"
      class="mt-2"
      link
      @click="deleteBudget"
    >
      <v-list-item-icon>
        <v-icon color="error"> fas fa-trash </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="error--text"> Excluir </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emails: [],
    }
  },
  computed: {
    ...mapGetters('comercial', ['showDeleteBudgets']),
  },
  methods: {
    ...mapMutations(['setSendEmailLoading']),
    duplicateBudget() {
      this.$api
        .post(`budget/duplicate`, { id: this.item.id })
        .then(() => {
          contextMenu.action('budgets')

          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Orçamento duplicado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops! Falha o duplicar este orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    sendModal() {
      this.$store.commit('setModal', {
        show: true,
        component: 'SendEmail',
        text: 'Qual email deseja enviar o PDF?',
        caption: 'Selecione abaixo.',
        client_id: this.item.client_id,
        confirm: (response) => {
          this.sendPdf(response)
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    sendPdf(response) {
      this.setSendEmailLoading()
      this.$api
        .post(`budget/pdf`, {
          id: this.item.id,
          body: response.body,
          emails: response.emails,
        })
        .then(() => {
          this.setSendEmailLoading()
          this.$store.commit('setModal', {
            show: false,
          })
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'PDF enviado com sucesso!',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })

          //Alterando o status do orcamento
          this.sendBudget()
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar enviar PDF',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    sendBudget() {
      this.$api
        .patch(`budget/status`, {
          id: this.item.id,
          status: 1,
        })
        .then(() => {
          contextMenu.action('budgets')

          this.item.status = 'sended'
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar atualizar o status deste orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    async approveBudget() {
      try {
        const [client, getBudgetData] = await Promise.all([
          this.$api.get(`people/${this.item.client_id}`),
          this.$api.post(`budget/show`, { id: this.item.id }),
        ])

        if (!client.data?.data?.client_address?.length) {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Erro na aprovação deste orçamento',
            caption: 'Cliente sem endereço.',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })

          return
        }

        if (getBudgetData?.data?.data?.products?.length) {
          try {
            const response = await this.$api.patch('budget/status', {
              id: this.item.id,
              status: 2,
            })

            if (response.data.status === 'success') {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Orçamento aprovado com sucesso',
                caption:
                  'Seu orçamento se tornou um pedido, navegue até a seção PEDIDOS.',
                confirm: () => {
                  this.$store.commit('setModal', { show: false })
                },
              })
            } else {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Falha ao tentar aprovar este orçamento',
                caption: response.data.message || '',
                confirm: () => {
                  this.$store.commit('setModal', { show: false })
                },
              })
            }
          } catch (err) {
            console.error(err)
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Falha ao tentar aprovar este orçamento',
              caption:
                err.response?.data?.message || 'Ocorreu um erro inesperado.',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
          } finally {
            contextMenu.action('budgets')
          }
        } else {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Erro na aprovação deste orçamento',
            caption:
              'Verifique se a <strong><u>tabela de valores</u></strong> dos produtos estão corretamente selecionadas',
            confirm: () => {
              this.$store.commit('setModal', { show: false })
            },
          })
        }
      } catch (e) {
        console.log(e)
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ocorreu um erro inesperado.',
          caption: 'Por favor, tente novamente mais tarde.',
          confirm: () => {
            this.$store.commit('setModal', { show: false })
          },
        })
      }
    },

    disapproveBudget() {
      this.$store.commit('setModal', {
        show: true,
        component: 'Cancel',
        text: 'Qual é o motivo para o Cancelamento do Orçamento?',
        caption: 'Selecione a baixo.',
        confirm: (response) => {
          this.$store.commit('setModal', {
            show: false,
          })
          this.sendCancelReason(response)
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    sendCancelReason(cancelReason) {
      this.$api
        .patch(`budget/status`, {
          status: 3,
          id: this.item.id,
          cancel_description: cancelReason,
          user_id: 1,
        })
        .then(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'success',
            text: 'Orçamento reprovado com sucesso',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
              contextMenu.action('budgets')
              this.item.status = 3
            },
          })
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar atualizar o status deste orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    showPDF() {
      let company_name = this.item.company.replace(' ', '_')
      this.$api
        .post(`budget/seepdf`, {
          id: this.item.id,
          company_name: company_name,
          client_company_name: this.item.client_company_name,
        })
        .then((res) => {
          window.open(
            `${this.$store.state.imagePath}${res.data.data}`,
            '_blank'
          )

          contextMenu.action('budgets')
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao gerar PDF deste orçamento',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
    deleteBudget() {
      this.$store.commit('setModal', {
        show: true,
        component: 'warning',
        text: 'Atenção!',
        caption: 'Deseja mesmo deletar este orçamento?',
        confirm: () => {
          this.$api
            .post(`budget/delete`, { id: this.item.id })
            .then(() => {
              this.$store.commit('setModal', {
                show: true,
                component: 'success',
                text: 'Orçamento excluido com sucesso',
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                  contextMenu.action('budgets')
                },
              })
            })
            .catch((err) => {
              this.$store.commit('setModal', {
                show: true,
                component: 'error',
                text: 'Ops! Falha ao tentar excluir este orçamento',
                caption: err.response.data.message,
                confirm: () => {
                  this.$store.commit('setModal', {
                    show: false,
                  })
                },
              })
            })
        },
        negate: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>
