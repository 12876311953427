<template>
  <v-row dense>
    <!-- <v-col
      cols="6"
      md="6"
    >
      <v-text-field
        v-model="observationDate"
        label="Data"
        placeholder="DD/MM/AAAA"
        v-mask="['##/##/####']"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col>

  
    <v-col
      cols="6"
      md="6"
    >
      <v-text-field
        v-model="observationTime"
        label="Hora"
        placeholder="Horas e Minutos"
        v-mask="['##:##']"
        outlined
        rounded
        dense
        hide-details
      />
    </v-col> -->

    <v-col
      cols="12"
      md="12"
    >
      <v-textarea
        v-model="observation"
        label="Nova Observação"
        outlined
        rounded
        dense
        auto-grow
      />
      <v-btn
        color="primary"
        width="100%"
        @click="addObservation"
        rounded
        outlined
        class="mt-2"
        :disabled="!observation"
      >
        Adicionar Observação
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-card
        flat
        v-if="historic.length > 0"
        class="pa-5"
      >
        <h6 class="overline secondary--text">Histórico</h6>

        <div
          v-for="(obs, idx) in crmHistoric"
          :key="idx"
          class="mb-3"
        >
          <div class="d-flex justify-space-between align-center">
            <h6
              class="text-h6 primary--text"
              v-if="obs.created_at"
            >
              {{ obs.created_at | convertDate }}
            </h6>
            <v-btn
              v-if="obs.id"
              @click="removeObservation(idx, obs.id)"
              icon
              color="error"
            >
              <v-icon small>fas fa-trash</v-icon>
            </v-btn>
          </div>

          <p class="body-2">{{ obs.observation }}</p>
          <v-divider class="my-2" />
        </div>
      </v-card>

      <div v-else>
        <h6 class="text-center text-h5">Nenhuma observação adicionada</h6>
      </div>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="310"
      >
        <v-card>
          <v-card-title class="headline">Confirmação</v-card-title>
          <v-card-text
            >Você tem certeza que deseja remover esta observação?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="cancelRemoval"
              >Cancelar</v-btn
            >
            <v-btn
              color="green darken-1"
              text
              @click="confirmRemoval"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    crmHistoric: {
      type: Array,
      default: () => [],
    },
    CRM_ID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      observation: null,
      // observationDate: null,
      // observationTime: null,
      historic: [],
      dialog: false,
      selectedObservation: null,
    }
  },
  watch: {
    crmHistoric: {
      handler(newVal) {
        this.historic = newVal
      },
      immediate: true,
      deep: true,
    },
  },
  filters: {
    convertDate(value) {
      if (!value) return ''
      let dt = null
      if (typeof value === 'string') {
        dt = new Date(value)
        if (isNaN(dt.getTime())) {
          return value
        }
      } else if (value instanceof Date) {
        dt = value
      } else {
        return value
      }
      return dt.toLocaleString('pt-BR').replace(', ', ' - ')
    },
  },
  methods: {
    addObservation() {
      const observationObj = {
        observation: this.observation,
      }
      this.$emit('setHistoric', observationObj)
      this.observation = null
    },
    removeObservation(index, id) {
      this.selectedObservation = { index, id }
      this.dialog = true
    },
    confirmRemoval() {
      this.$emit('removeObservation', this.selectedObservation)
      this.dialog = false
    },
    cancelRemoval() {
      this.dialog = false
    },
  },
}
</script>

<style scoped></style>
