<template>
  <v-sheet
    class="grey lighten-3 pa-4 my-3"
    rounded="lg"
  >
    <v-row
      dense
    >
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="location.type"
          :rules="[rules.required]"
          label="Tipo"
          placeholder="Tipo"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-model="location.zipcode"
          v-mask="'#####-###'"
          :rules="[rules.required, rules.validZipcode]"
          label="CEP"
          placeholder="xxxxx-xxx"
          append-icon="fas fa-search-location"
          outlined
          rounded
          dense
          hide-details
          @blur="getAddress"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="location.public_place"
          :rules="[rules.required]"
          label="Endereço"
          placeholder="Digite o nome da rua"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="location.number"
          :rules="[rules.required, rules.isNumber]"
          label="N°"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="location.complement"
          label="Complemento"
          placeholder="Complemento"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="location.reference_point"
          label="Ponto de Referência"
          placeholder="Digite o ponto de referência"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="location.neighborhood"
          :rules="[rules.required]"
          label="Bairro"
          placeholder="Digite o nome do bairro"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="location.city"
          :rules="[rules.required]"
          label="Cidade"
          placeholder="Digite o nome da cidade"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="location.state"
          :rules="[rules.required, rules.validState]"
          label="UF"
          placeholder="SP"
          :loading="isLoading"
          :disabled="isDisabled"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          rounded
          outlined
          color="primary"
          @click="$emit('removeLocation')"
        >
          Remover Endereço
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isLoading: false,
    isDisabled: false,
    rules: {
      required: (value) => !!value || "Este campo é obrigatório",
      validZipcode: (value) =>
        /^[0-9]{5}-[0-9]{3}$/.test(value) || "CEP inválido",
      validState: (value) =>
        /^[A-Z]{2}$/.test(value) || "UF deve conter duas letras maiúsculas",
      isNumber: (value) =>
        !isNaN(Number(value)) || "Deve ser um número válido",
    },
  }),
  methods: {
    getAddress() {
      this.isLoading = true;

      this.$api
        .get(`https://cep.awesomeapi.com.br/json/${this.location.zipcode}`)
        .then((res) => {
          this.location.city = res.data.city;
          this.location.neighborhood = res.data.district;
          this.location.state = res.data.state;
          this.location.public_place = res.data.address;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
