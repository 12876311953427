<template>
  <v-row class="px-10" dense>
    <v-col rows="12" md="6">
      <v-text-field v-model="company.company_name" :rules="[() => !!company.company_name || 'Este campo é obrigatório']"
        label="Nome*" placeholder="Digite o nome" outlined rounded dense />
    </v-col>
    <v-col rows="12" md="6">
      <v-text-field v-model="company.tranding_name" label="Nome Fantasia" placeholder="Digite o nome" outlined rounded
        dense />
    </v-col>

    <v-col cols="12" md="8">
      <v-text-field v-model="company.email" :rules="[
        () => !!company.email || 'Este campo é obrigatório',
        rules.email,
      ]" label="Email*" placeholder="exemplo@gmail.com" outlined rounded dense />
    </v-col>

    <v-col cols="12" md="4">
      <v-text-field v-model="company.phone_number" label="Telefone" v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(xx)x.xxxx-xxxx" type="tel" outlined rounded dense />
    </v-col>

    <v-col cols="5" md="5">
      <v-file-input v-model="company.logo" label="Logo da Empresa" accept="image/*" placeholder="Selecione uma imagem"
        show-size hide-details outlined rounded dense class="mt-5 small-file-input" />
    </v-col>

    <v-col cols="12" md="4" class="d-flex justify-center" v-if="logoPreview || company.logo_url">
      <v-img :src="logoPreview || company.logo_url" max-height="200" class="mt-8" alt="Logo da Empresa" contain />
      <v-btn icon color="error" @click="removeLogo">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    company: Object,
  },
  data: () => ({
    logoPreview: null,
    menu: false,
    activePicker: null,
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Insira um email válido'
      },
    },
    sellers: null,
  }),
  computed: {
    birthday: function () {
      if (this.company.birthday != null) {
        let BR_dateFormat = this.company.birthday.split('-')
        BR_dateFormat = BR_dateFormat.reverse()
        BR_dateFormat = BR_dateFormat.join('/')
        return BR_dateFormat
      } else {
        return ''
      }
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    'company.logo': {
      handler(newVal) {
        if (newVal && newVal instanceof File) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.logoPreview = e.target.result
          }
          reader.readAsDataURL(newVal)
        } else {
          this.logoPreview = null
        }
      },
      immediate: true,
    },
  },

  methods: {
    saveDate(date) {
      this.$refs.menu.save(date)
    },
    removeLogo() {
      this.$api.delete(`companies/${this.company.id}/logo`)
        .then(() => {
          this.company.logo_url = null
          this.company.logo = null
          this.logoPreview = null
        })
    },
  },
}
</script>
