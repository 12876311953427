<template>
  <v-container fluid>
    <FilterDrawer
      current-component="products-filter"
      @filter="filterProducts"
      @clear="clearFilter"
    />
    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      :total-register="dataTable.totalRegister"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template slot="title-action">
        <v-btn
          class="mr-2"
          x-large
          color="primary"
          icon
          @click="showBigImages = !showBigImages"
        >
          <v-icon size="20">
            {{
              showBigImages
                ? 'fas fa-compress-arrows-alt'
                : 'fas fa-expand-arrows-alt'
            }}
          </v-icon>
        </v-btn>
      </template>

      <template slot="new-btn">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              class="mx-2"
              color="primary"
              rounded
              v-on="on"
            >
              Novo
              <v-icon right>
                fas fa-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-list-item @click="actionNew">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    fas fa-people-carry
                  </v-icon>
                  Fornecido
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="actionNewComposed">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    fas fa-puzzle-piece
                  </v-icon>
                  Composto
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="actionNewTemporary">
                <v-list-item-title>
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-clock
                  </v-icon>
                  Temporário
                </v-list-item-title>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:tbody="{ item }">
        <td>
          <ImageAvatar
            default-icon="fas fa-box"
            :big-image="showBigImages"
            :img_src="item.product_image"
          />
        </td>
        <td>
          <ul class="pa-0">
            <li>{{ item.product_name }}</li>
            <li>{{ item.product_reference }}</li>
          </ul>
        </td>
        <td>
          <ul class="pa-0">
            <li>{{ item.selected_vendor?.company_name }}</li>
            <li>Cód: {{ item.code_fornecedor }}</li>
          </ul>
        </td>
        <td class="text-right">
          <div
            v-if="item.selected_data_vendor && item.selected_data_vendor.cost_tables.length && item.selected_data_vendor.cost_tables[0].is_fixed"
            class="format-value"
          >
            <span>{{ formatCost(item?.selected_data_vendor?.cost_tables[0]?.rows[0]?.cost)}}</span>
            <span>{{ item?.updated_at ? item?.updated_at : new Date(item.vendors?.updated_at).toLocaleDateString('pt-BR') }}</span>
          </div>
          <div
            v-else-if="!item?.selected_data_vendor">
            <span> - </span>
          </div>
          <div v-else-if="item.is_composition">
            <v-icon
              small
              color="primary"
              left
            >
              fas fa-puzzle-piece
            </v-icon>
            <span>Composição</span>
          </div>
          <div v-else>
            <v-icon
              small
              color="primary"
              left
            >
              fas fa-table
            </v-icon>
            <span>Tabela</span>
          </div>
        </td>
        <td
          class="d-flex justify-center align-center"
          style="gap: 10px;"
        >
          <div>{{ item.vendor_date }}</div>
          <StatusChip
            :status="vendorStatus"
            :current-status="item.vendor_status"
          />
        </td>
        <td class="text-center">
          {{ item.factor_cost }}
        </td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
      </template>
    </Table>

    <v-dialog
      v-model="temporaryFormModal"
      max-width="600"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span>Produto temporário</span>
          <v-spacer />
          <v-btn
            icon
            @click="temporaryFormModal = false"
          >
            <v-icon color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <TemporaryProductsForm
            ref="temporaryForm"
            @success="tempCreated"
            @error="tempCreationFailed"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="primary"
            rounded
            @click="createTemporary"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import TemporaryProductsForm from './TemporaryProductsForm'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import ImageAvatar from '@/components/data-table/ImageAvatar'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    ImageAvatar,
    Table,
    StatusChip,
    FilterDrawer,
    TemporaryProductsForm,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    temporaryFormModal: false,
    showBigImages: false,
    currentSearch: '',
    vendorStatus: [
      { value: 1, text: 'Ok', color: 'success' },
      { value: 2, text: 'Consulta', color: 'cyan' },
      { value: 3, text: 'Sem estoque', color: 'error' },
      { value: 4, text: 'Fora de linha', color: 'grey' },
    ],
    filters:{},
    dataTable: {
      title: 'Produtos',
      new: 'products-new',
      edit: 'products-edit',
      context: 'products-context',
      massAction: 'products-mass-action',
      totalRegister: 1,
      totalItems: 0,
      headerButtons: {
        filter: true,
        new: false,
        newMenu: true,
        expandRow: true,
      },
      items: [],
      isLoading: true,
      headers: [
        {
          text: 'Imagem',
          value: 'product_image',
          align: 'center',
          sortable: false,
          width: 50,
        },
        { text: 'Nome', value: 'product_name' },
        { text: 'Fornecedor', value: 'product_vendor_name' },
        { text: 'Custo Fixo', value: 'product_fixed_cost', align: 'end' },
        { text: 'Status Fornecedor', value: 'vendor_status', align: 'center' },
        { text: 'Tabela Fator', value: 'factor_cost', align: 'center' },
        { text: 'Status', value: 'status', align: 'center' },
      ],
    },
  }),
 async mounted() {
    await this.getProducts()

    contextMenu.$once('action', async (type) => {
      if (type === 'products') {
        await this.getProducts()
      }
    })
    massAction.$once('action', async () => {
      await this.getProducts()
    })
  },
  methods: {
    formatCost(value) {
      if (typeof value !== 'number') {
        return 'R$ 0,00'; // Ou qualquer valor padrão que você queira exibir
      }
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });
    },
    async getProducts(page = 1) {
      this.dataTable.items = [];
      this.dataTable.isLoading = true;

      try {
        const res = await this.$api.get('products', {
          params: {
            page,
            ...this.filters,
            ...(this.currentSearch ? { name: this.currentSearch } : {}),
          },
        });
        this.dataTable.totalPages = res.data.total_pages;
        this.dataTable.totalRegister = res.data.total_products;
        this.dataTable.items = res.data.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.dataTable.isLoading = false;
      }
    },
    filterProducts(filter) {
      this.dataTable.items = []
      this.dataTable.isLoading = true
      this.filters = filter

      this.$api
        .get('products', {
          params: {
            ...filter,
            page: 1,
          }
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.total_pages;
        this.dataTable.totalRegister = res.data.total_products;
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    async clearFilter() {
      this.filters = {}
      this.currentSearch = ''
      await this.getProducts()
    },
    actionNew() {
      this.$router.push({
        name: this.dataTable.new,
        params: { isComposed: false },
      })
    },
    async setCurrentPage(page) {
      await this.getProducts(page)
    },
    async search(search) {
      this.currentSearch = search
      await this.getProducts()
    },
    actionNewComposed() {
      this.$router.push({
        name: this.dataTable.new,
        params: { isComposed: true },
      })
    },
    actionNewTemporary() {
      this.temporaryFormModal = true
    },
    createTemporary() {
      this.$refs.temporaryForm.createTemporary()
    },
    tempCreated() {
      this.$store.commit('setModal', {
        show: true,
        component: 'success',
        text: 'Produto temporário cadastrado com sucesso',
        confirm: () => {
          this.$router.push({ name: 'products' })
          this.temporaryFormModal = false
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
    tempCreationFailed() {
      this.$store.commit('setModal', {
        show: true,
        component: 'error',
        text: 'Erro ao cadastrar produto temporário',
        confirm: () => {
          this.$store.commit('setModal', {
            show: false,
          })
        },
      })
    },
  },
}
</script>

<style scoped>
  .format-value {
    display: flex;
    flex-direction: column;
  }
</style>