<template>
  <v-form
    ref="form"
    @submit.prevent="validateForm"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="2"
      >
        <v-autocomplete
          v-model="filter.product_reference"
          :items="codes.items"
          :search-input.sync="filter.codes"
          item-text="product_reference"
          outlined
          rounded
          dense
          flat
          label="Código"
          @change="getPrintsByCode(filter.product_reference)"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="codes.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma gravação encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="filter.product_name"
          label="Nome"
          placeholder="Nome do produto"
          color="primary"
          outlined
          rounded
          dense
          flat
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.print"
          label="Gravação"
          :items="print.items"
          item-text="name"
          item-value="id"
          color="primary"
          :loading="print.customFilter"
          outlined
          rounded
          dense
          flat
          @focus="getPrints"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="print.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma gravação encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="filter.vendor_status"
          label="Status do Fornecedor"
          :items="vendor_status_table"
          item-text="title"
          item-value="id"
          color="primary"
          outlined
          rounded
          dense
          flat
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar size="20">
              <v-icon
                small
                :color="item.color"
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.categories"
          label="Categoria"
          :items="category.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
          @focus.once="getCategories"
          @input="getPrintsByCategory"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="category.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma categoria encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.subcategories"
          label="Subcategoria"
          :items="subcategory.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
          @focus.once="getSubCategories"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="subcategory.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma subcategoria encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.segment"
          label="Segmento"
          :items="segment.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
          @focus.once="getSegments"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="segment.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhum segmento encontrado
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-autocomplete
          v-model="filter.special_dates"
          label="Datas especiais"
          :items="specialDate.items"
          item-text="name"
          item-value="id"
          rounded
          dense
          color="primary"
          outlined
          flat
          @focus.once="getSpecialDates"
        >
          <template v-slot:no-data>
            <div class="pa-2">
              <v-progress-linear
                v-if="specialDate.isLoading"
                indeterminate
                color="primary"
              />
              <h6
                v-else
                class="text-subtitle-2 text-center"
              >
                Nenhuma data especial encontrada
              </h6>
            </div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <h6 class="text-subtitle-2">
          Preço de venda
        </h6>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="filter.from_cost"
          :disabled="!filter.print"
          label="De :"
          placeholder="Preço de venda inicial"
          color="primary"
          outlined
          rounded
          dense
          flat
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-text-field
          v-model="filter.to_cost"
          :disabled="!filter.print"
          label="Até :"
          placeholder="Preço de venda limite"
          color="primary"
          outlined
          rounded
          dense
          hide-details
          flat
        />
      </v-col>
      <v-col
        align="right"
        cols="12"
        md="10"
      >
        <v-btn
          rounded
          dense
          color="primary"
          outlined
          dark
          class="mx-2"
          @click="clearFilter"
        >
          Limpar filtro
        </v-btn>

        <v-btn
          type="submit"
          rounded
          dense
          color="primary"
          class="mx-2"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense />
  </v-form>
</template>

<script>
export default {
  data: () => ({
    filter: { codes: null, quantity: null },
    codes: {
      items: [],
      isLoading: true,
    },
    category: {
      items: [],
      isLoading: true,
    },
    subcategory: {
      items: [],
      isLoading: true,
    },
    segment: {
      items: [],
      isLoading: true,
    },
    specialDate: {
      items: [],
      isLoading: true,
    },
    print: {
      items: [],
      isLoading: true,
      customFilter: false,
    },
    vendor_status_table: [
      { id: 1, title: 'Ok', icon: 'fas fa-check', color: 'success' },
      { id: 2, title: 'Consultar', icon: 'fas fa-search', color: 'blue' },
      { id: 3, title: 'Sem estoque', icon: 'fas fa-box-open', color: 'error' },
      {
        id: 4,
        title: 'Fora de linha',
        icon: 'fas fa-times-circle',
        color: 'grey',
      },
    ],
    modalError: false,
    modalFrom: false,
    modalTo: false,
    dialogFrom: false,
    dialogTo: false,
  }),
  computed: {
    dateFromConverted: {
      get: function () {
        if (this.filter.dateFrom) {
          let BR_dateFormat = this.filter.dateFrom.split('-')
          BR_dateFormat = BR_dateFormat.reverse()
          BR_dateFormat = BR_dateFormat.join('/')
          return BR_dateFormat
        } else {
          return ''
        }
      },
      set: function (value) {
        return (this.filter.dateFrom = value)
      },
    },
    dateToConverted: {
      get: function () {
        if (this.filter.dateTo) {
          let BR_dateFormat = this.filter.dateTo.split('-')
          BR_dateFormat = BR_dateFormat.reverse()
          BR_dateFormat = BR_dateFormat.join('/')
          return BR_dateFormat
        } else {
          return ''
        }
      },
      set: function (value) {
        return (this.filter.dateTo = value)
      },
    },
  },
  watch: {
    'filter.print'(newvalue) {
      this.$emit('printSelected', newvalue)
    },
    'filter.codes'(newvalue) {
      this.searchCodes(newvalue)
    },
  },
  methods: {
    showAlert(message) {
      this.alertMessage = message
      this.modalAlert = true
    },
    searchCodes(code) {
      this.$api
        .get(`codes/${code}`)
        .then((res) => {
          this.codes.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    validateForm() {
      const isValid = this.$refs.form.validate()

      let code = this.filter.product_reference

      if (code && code.length >= 7) {
        this.getPrintsByCode(code)
      }

      if (isValid) {
        this.$emit('filter', this.filter)
        this.$api.post('budget', this.filter)
          .then((res) => {
            const filteredProducts = res.data.data.filter(product => product.status === true)
            this.print.items = filteredProducts
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    clearFilter() {
      this.filter = {}
      this.print.items = []
      this.$emit('clearFilter')
    },
    getPrintsByCategory(e) {
      this.subcategory.items = []
      this.filter.subcategories = null
      this.print.customFilter = true
      this.getSubCategories()
      this.$api
        .get(`product_prints_by_category/${e}`)
        .then((res) => {
          this.print.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.print.customFilter = false
        })
    },
    getPrintsByCode(code) {
      this.print.customFilter = true;
      this.$api
        .get(`product_prints_by_code/${code}`)
        .then((res) => {
          const filteredProducts = res.data.data.filter(product => product.status === true || product.status === false);
          if (filteredProducts.length === 0) {
            this.modalError = true;
          }
          this.print.items = filteredProducts;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.print.customFilter = false;
        });
    },
    getCategories() {
      this.$api
        .get('categories/main_categories')
        .then((res) => {
          this.subcategory.items = []
          this.filter.subcategories = null
          this.category.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.category.isLoading = false
        })
    },
    getSubCategories() {
      let url = 'categories/subcategories';
      if (this.filter?.categories) {
        url += `?category_id=${this.filter?.categories}`;
      }

      this.$api
        .get(url)
        .then((res) => {
          this.subcategory.items = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.subcategory.isLoading = false;
        });
    },
    getSegments() {
      this.$api
        .get('categories/segments')
        .then((res) => {
          this.segment.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.segment.isLoading = false
        })
    },
    getSpecialDates() {
      this.$api
        .get('categories/special_dates')
        .then((res) => {
          this.specialDate.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.specialDate.isLoading = false
        })
    },
    getPrints() {
      if (this.print.items.length == 0) {
        this.print.isLoading = true
        this.$api
          .get('product_prints')
          .then((res) => {
            this.print.items = res.data.data
          })
          .catch((err) => {
            console.error(err)
          })
          .finally(() => {
            this.print.isLoading = false
          })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.primary-border {
  border: 1px solid var(--v-primary-base);
}
</style>
