<template>
  <v-form
    ref="form"
    v-model="isValid"
  >
    <SectionTitle title="Dados do Cliente">
      <template v-slot:action-buttons>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="seeClientDetails"
              v-on="on"
              v-bind="attrs"
              class="mr-2"
              icon
              color="primary"
              small
            >
              <v-icon small>fas fa-eye</v-icon>
            </v-btn>
          </template>
          <span>Ver detalhes do cliente</span>
        </v-tooltip>
      </template>
    </SectionTitle>
    <ClientData :client="client" />

    <SectionTitle title="Atividade" />
    <Activity :crm="crm" />

    <SectionTitle title="Observações" />
    <Observations
      @setHistoric="addObservation"
      @removeObservation="removeObservation"
      :crmHistoric="crm.historic"
      :CRM_ID="crm.id"
    />
  </v-form>
</template>

<script>
export default {
  components: {
    SectionTitle: () => import('@/components/SectionTitle'),
    ClientData: () => import('./form-sections/ClientData'),
    Activity: () => import('./form-sections/Activity'),
    Observations: () => import('./form-sections/Observations'),
  },
  props: {
    valid: Boolean,
    crm: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    rows: [],
    selectedProducts: [],
    products: [],
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      if (isValid) {
        this.$emit('save', this.crm)
      }
    },
    addObservation(newObs) {
      if (this.crm.id) {
        this.$api
          .post('crm/newObservation', {
            crm_id: this.crm.id,
            observation: newObs.observation,
          })
          .then((res) => {
            const createdObs = res.data.data
            this.crm.historic.unshift(createdObs)
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Observação adicionada com sucesso!',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
          })
          .catch((err) => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Falha ao adicionar a observação.',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
            console.error(err)
          })
      } else {
        this.crm.historic.unshift({
          observation: newObs.observation,
        })
      }
    },
    removeObservation({ index, id }) {
      if (id) {
        this.$api
          .delete(`crm/messages/${id}`)
          .then(() => {
            this.crm.historic.splice(index, 1)
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Observação excluída com sucesso!',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
          })
          .catch((err) => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Falha ao excluir a observação.',
              confirm: () => {
                this.$store.commit('setModal', { show: false })
              },
            })
            console.error(err)
          })
      } else {
        this.crm.historic.splice(index, 1)
      }
    },
    addProduct(product) {
      this.selectedProducts.push(product)
    },
    removeProduct(product) {
      let idx = this.selectedProducts.indexOf(product)
      this.selectedProducts.splice(idx, 1)
    },
    rowsChanged(rows) {
      this.rows = rows
    },
    seeClientDetails() {
      const routeData = this.$router.resolve({
        path: '/people/clients/' + this.client.id,
      })
      window.open(routeData.href, '_blank')
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
.cke_chrome {
  border-radius: 30px;
  border: 1px solid #aaa !important;
  transition: all 0.2s;

  &:hover {
    border: 1px solid #555 !important;
  }
}

.cke_inner {
  background: none !important;
}

.cke_top {
  border-radius: 30px 30px 0 0;
}

.cke_bottom {
  background: none !important;
  padding: 6px 19px !important;
}
</style>
