<template>
  <v-container
    class="pa-0"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.name"
          outlined
          label="Nome"
          placeholder="Nome, razão social ou nome fantasia"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.cpf_cnpj"
          outlined
          label="CPF/CNPJ"
          placeholder="CPF ou CNPJ"
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="filter.contact_name_email"
          outlined
          label="Nome ou E-mail de contato"
          placeholder="Digite o nome ou email de contato"
          rounded
          dense
          hide-details
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="filter.phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          outlined
          label="Telefone/Celular"
          placeholder="(00) 00000-0000"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="filter.status"
          :items="status"
          item-text="title"
          item-value="value"
          label="Status"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="filter.seller"
          label="Vendedor"
          :items="seller.items"
          item-text="name"
          item-value="id"
          outlined
          rounded
          dense
          hide-details
          @focus.once="getSellers"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="seller.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum cliente encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.email"
          outlined
          label="Email"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de cadastro </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_from"
          v-mask="['##/##/####']"
          label="De"
          dense
          rounded
          outlined
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.date_to"
          v-mask="['##/##/####']"
          label="Até"
          dense
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    seller: {
      items: [],
      loading: true,
    },
    status: [
      { title: 'Inativo', value: false },
      { title: 'Ativo', value: true },
    ],
  }),
  methods: {
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
