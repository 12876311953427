<template>
  <v-list
    class="pt-0"
    dense
  >
    <v-subheader
      class="primary subtitle-2"
      dark
    >
      <span class="u-text-overflow">{{ item.name }}</span>
    </v-subheader>

    <v-list-item
      @click="updateStatus"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon>
          {{ item.status ? 'fas fa-times-circle' : 'fas fa-check' }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.status ? 'Desativar' : 'Ativar' }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- 
    <v-list-item
      @click="deleteCompany"
      class="mt-2"
      link
    >
      <v-list-item-icon>
        <v-icon color="error">fas fa-trash</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="error--text"> Excluir </v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
import { contextMenu } from '@/main.js'
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateStatus() {
      this.$api
        .patch(`companies/status/${this.item.id}`, {
          status: !this.item.status,
        })
        .then(() => {
          contextMenu.action('companies')

          this.item.status = !this.item.status
        })
        .catch(() => {
          this.$store.commit('setModal', {
            show: true,
            component: 'error',
            text: 'Ops!',
            caption: 'Falha ao tentar atualizar o status deste agência',
            confirm: () => {
              this.$store.commit('setModal', {
                show: false,
              })
            },
          })
        })
    },
  },
  deleteCompany() {
    this.$store.commit('setModal', {
      show: true,
      component: 'warning',
      text: 'Atenção!',
      caption: 'Deseja mesmo deletar este empresa?',
      confirm: () => {
        this.$api
          .post(`company/delete/`, { id: this.item.id })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Empresa excluida com sucesso',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
                contextMenu.action('companies')
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar excluir este empresa',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      },
      negate: () => {
        this.$store.commit('setModal', {
          show: false,
        })
      },
    })
  },
}
</script>
