<template>
  <v-container
    class="pa-0 pr-4"
    fluid
  >
    <FilterHeader
      @filter="filterAction"
      @clear="clearAction"
    />

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="filter.company_name"
          outlined
          label="Nome da empresa"
          placeholder="Nome Fantasia ou Razão Social"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="filter.priority"
          outlined
          label="Prioridade"
          :items="['Baixa', 'Média', 'Alta']"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="filter.alert"
          outlined
          label="Alerta"
          :items="[
            'Ligação futura',
            'Dia de ligar',
            'Deixou de ligar no dia',
            'Ligação atrasada',
          ]"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="filter.seller"
          outlined
          label="Vendedor"
          :items="seller.items"
          item-value="id"
          item-text="name"
          rounded
          dense
          hide-details
          @focus.once="getSellers"
        >
          <template v-slot:no-data>
            <v-progress-linear
              v-if="seller.loading"
              indeterminate
            />
            <h6
              v-else
              class="text-h6 grey--text text-center"
            >
              Nenhum Vendedor encontrado
            </h6>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.contact_phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          outlined
          label="Telefone/Celular do contato"
          placeholder="(00) 00000-0000"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="filter.contact_name_email"
          outlined
          label="Nome/E-mail"
          placeholder="Digite o nome ou email do contato"
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data do último contato </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.send_date_from"
          v-mask="['##/##/####']"
          label="De:"
          placeholder="00/00/0000"
          dense
          hide-details
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.send_date_to"
          v-mask="['##/##/####']"
          label="Até:"
          placeholder="00/00/0000"
          dense
          hide-details
          outlined
          rounded
        />
      </v-col>
      <v-col cols="12">
        <v-icon
          color="primary"
          small
        >
          fas fa-calendar-alt
        </v-icon>
        <span class="text-body-2"> Data de retorno </span>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.return_date_from"
          v-mask="['##/##/####']"
          label="De:"
          placeholder="00/00/0000"
          dense
          hide-details
          outlined
          rounded
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter.return_date_to"
          v-mask="['##/##/####']"
          label="Até:"
          placeholder="00/00/0000"
          dense
          hide-details
          outlined
          rounded
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterHeader from '@/components/FilterHeader'

export default {
  components: {
    FilterHeader,
  },
  data: () => ({
    filter: {},
    rules: {
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Insira um email válido'
      },
    },
    seller: {
      items: [],
      loading: true,
    },
  }),
  methods: {
    getSellers() {
      this.$api
        .get('people/type/sellers')
        .then((res) => {
          this.seller.items = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.seller.loading = false
        })
    },
    filterAction() {
      this.$emit('filter', this.filter)
    },
    clearAction() {
      this.$emit('clear')
      this.filter = {}
    },
  },
}
</script>
