<template>
  <CompaniesForm
    @save="createCompany"
    :company="company"
    :type="'create'"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    CompaniesForm: () => import('./CompaniesForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    company: {},
    askToLeave: true,
  }),
  methods: {
    createCompany() {
      this.askToLeave = false

      let hasFile = this.company.logo instanceof File

      if (hasFile) {
        let formData = new FormData()
        for (let key in this.company) {
          if (key === 'logo' && this.company[key] instanceof File) {
            formData.append(key, this.company[key], this.company[key].name)
          } else {
            formData.append(key, this.company[key] ?? '')
          }
        }
        this.$api.post('companies/store', this.company)
        this.$store
          .commit('setModal', {
            show: true,
            component: 'success',
            text: 'Empresa cadastrada com sucesso',
            confirm: () => {
              this.$router.push({ name: 'companies' })
            },
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar esta empresa.',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$api
          .post('companies/store', this.company)
          .then(() => {})
          .catch(() => {})
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
